const addZeroLeft = (t: any) => {
  if (t.length < 2 || t === 0) {
    return "0" + t;
  }
  return "" + t;
};

const timeConvert = (num: number) => {
  let hours = num / 60;
  let filteredHours = Math.floor(hours);
  let minutes = (hours - filteredHours) * 60;
  let filteredMinutes = Math.floor(minutes);
  return addZeroLeft(filteredHours) + ":" + addZeroLeft(filteredMinutes);
};

const timeGetObj = (num: number) => {
  let hours = num / 60;
  let filteredHours = Math.floor(hours);
  let minutes = (hours - filteredHours) * 60;
  return {
    hours: Math.floor(hours) * 60,
    minutes: minutes,
  };
};

export { timeConvert, timeGetObj };