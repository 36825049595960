import React from "react";
// mport { CustomMoment } from "../../interfaces/CustomMoment";
import "./index.scss";

import NotificationMenu from "../../components/NotificationMenu/index";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import backgroundNotifications from "../../images/backgroundNotifications.png";

interface NotificationsInterface {
  notification: any;
  date: any;
  id: any;
  key: any;
}

const Notifications: React.FC<NotificationsInterface> = ({
  notification,
  date,
  id,
  key,
  children,
}) => {
  return (
    <div className="page-default notifications-container">
      <Header />
      <div className="notifications-content">
        <img
          className="notifications-content-image"
          src={backgroundNotifications}
          alt="Catskillet background"
        />
        <NotificationMenu
          notification={notification}
          date={null}
          id={null}
          key={id}
          // {...children}
        />
      </div>
      <div className="notifications-container-footer">
      </div>
      <Footer />
    </div>
  );
};

export default Notifications;