/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from "react";
import "./index.scss";

import CalendarHeader from "./CalendarHeader";
import buildCalendar from "./build";
import { getColorClass, dayStyles } from "./CalendarHelper";
import { CustomMoment } from "../../interfaces/CustomMoment";

import { useAppContext } from "../../contexts/appContext";

interface CustomProps {
  activeDay: CustomMoment;
  onClickActiveDay(day: CustomMoment, openNav?: Boolean): void;
  calendarItems: any;
}

const days = [
  { letter: "D", name: "Dom" },
  { letter: "S", name: "Seg" },
  { letter: "T", name: "Ter" },
  { letter: "Q", name: "Qua" },
  { letter: "Q", name: "Qui" },
  { letter: "S", name: "Sex" },
  { letter: "SA", name: "Sab" },
];

const Calendar: React.FC<CustomProps> = ({
  activeDay,
  onClickActiveDay,
  calendarItems,
}) => {
  const [calendar, setCalendar] = useState<Array<Array<CustomMoment>>>();
  const { changeActiveDay } = useAppContext();

  useEffect(() => {
    setCalendar(buildCalendar(activeDay));
  }, [activeDay]);

  return (
    <section className="container-calendar">
      <CalendarHeader activeDate={activeDay} setActiveDate={onClickActiveDay} />
      <div className="calendar-body">
        <div className="calendar-days">
          {days.map((day, index) => (
            <div
              className={`calendar-week ${
                day.letter === "D" || day.letter === "SA" ? "hide" : ""
              } ${day.name === "Dom" || day.name === "Sab" ? "show" : ""}`}
              key={index + day.name}
            >
              <span className="name">{day.name}</span>
              <span className="letter">{day.letter}</span>
            </div>
          ))}
        </div>
        <div className="calendar-content">
          {!calendar ? (
            <h3>Deu algum problema</h3>
          ) : (
            <>
              {calendar.map((week, i) => (
                <div className="calendar-row" key={i}>
                  {week.map((itemDay, index) => {
                    const classStatus = getColorClass(
                      new Date(itemDay._d).toISOString(),
                      calendarItems
                    );

                    return (
                      <div
                        className={`calendar-day ${classStatus} ${
                          index === 0 || index === 6 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          onClickActiveDay(itemDay, true);
                        }}
                        key={index}
                      >
                        <div className={dayStyles(itemDay, activeDay)}>
                          <span>{itemDay.format("D").toString()}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Calendar;
