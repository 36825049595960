import React from "react";
import "./index.scss";

import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { DropdownButton, Dropdown } from "react-bootstrap";

import bell from "../../images/bell-icon.png";
import horizontalBar from "../../images/horizontal-bar.png";
import imageCatskilletLogo from "../../images/CatskilletLogo.png";
import barImage from "../../images/bar-image.png";

const Header: React.FC = () => {
  const { user, Logout } = useAuth();

  const getUserName = (name: any) => {
    const fullName = name.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  return (
    <div className="header-container">
      <div className="content-header-left-container">
        <Link to="/dashboard">
          <img
            className="content-header-left-image"
            src={imageCatskilletLogo}
            alt="Catskillet Logo"
          />
        </Link>
      </div>
      <div className="content-header-rigth">
        {/* <div className="content-header-rigth-bell-container">
          <Link to="/notifications">
            <img className="content-header-rigth-bell" src={bell} alt="Bell" />
          </Link>
        </div> */}

        <img
          className="content-header-barImage"
          src={barImage}
          alt="Bar icon"
        />

        <div className="content-header-profile-picture">
          <span>{getUserName(user.name)}</span>
        </div>

        <DropdownButton
          id="dropdown-basic-button"
          title={user.name.split(" ")[0]}
        >
          <Dropdown.Item onClick={Logout}>
            <img
              className="horizontal-bar"
              src={horizontalBar}
              alt="Bara horizontal"
            />
            <span>Sair</span>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};

export default Header;
