import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core";
import { useAuth } from "../../contexts/authContext";

const variantIcon: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: "#43a047",
  },
  error: {
    backgroundColor: "#fc473d",
  },
  info: {
    backgroundColor: "#86c9fd",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

interface MySnackbarProps {
  className?: string,
  message: string,
  onClose(event: any, reason: string): void,
  variant: string
}

const MySnackbarContentWrapper = (props: MySnackbarProps) => {
  const classes: any = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={((ev) => {
            onClose(ev, "")
          })}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

// MySnackbarContentWrapper.propTypes = {
//   className: PropTypes.string,
//   message: PropTypes.node,
//   onClose: PropTypes.func,
//   variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
// };

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedSnackbars(props: { isOpen?: any; variant?: any; message?: any; }) {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(props.isOpen);

  const { setAlerts } = useAuth();
  const { variant, message } = props;

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props]);

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlerts([])
    setOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={3500}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
}
