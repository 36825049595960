import React from "react";
import "./index.scss";
import Select from "react-select";
import { Form } from "react-bootstrap";

interface CustomProps {
  labelName: string;
  disabled?: boolean;
  id?: string;
  name: string;
  value?: string;
  options: any[];
  handler?(option: any): void;
  placeholder?: string;
  error?: string;
  className?: string;
}

const AdvancedSelect: React.FC<CustomProps> = ({
  labelName,
  disabled,
  id,
  name,
  value,
  options,
  handler,
  placeholder,
  error,
  className,
}) => {
  return (
    <>
      <div className="form-field">
        <Form.Group>
          {labelName ? <label className="custom-label">{labelName}</label> : ""}
          <Select
            options={options}
            placeholder={placeholder || ""}
            value={
              options && options.filter((option) => option.value === value)
            }
            isDisabled={disabled || false}
            className={`advanced-select ${error ?? "error"} ${className || ""}`}
            onChange={handler || undefined}
          />
        </Form.Group>
        {error && <span className="form-field__alert">{error}</span>}
      </div>
    </>
  );
};

export default AdvancedSelect;
