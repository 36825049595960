const validateFormAdd = (values: any) => {
    let errors: {
      client_id?: string;
      project_id?: string;
      status_id?: string;
      sum?: string;
      time?: string;
    } = {};

    if (values.client_id === "") {
      errors.client_id = "* Campo obrigatório";
    }

    if (values.project_id === "") {
      errors.project_id = "* Campo obrigatório";
    }

    if (values.status_id === "") {
      errors.status_id = "* Campo obrigatório";
    }

    if (!values.time) {
      errors.time = "* Campo obrigatório";
    } else if (values.time <= 0) {
      errors.time = "* Valor minímo de 30 minutos";
    }

    return errors;
}

export default validateFormAdd;