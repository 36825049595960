import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login/";
import Notifications from "../pages/Notifications";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const Routes: React.FC = () => {
  return (
    <Router
      // @ts-ignore
      history={history}
      // @ts-ignore
      render={(props) => getUserAfterChangeRoute()}
    >
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </Router>
  );
};

export default Routes;
