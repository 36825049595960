import React from "react";
import Routes from './routes';
import { AuthProvider } from './contexts/authContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss'
import { AppProvider } from "./contexts/appContext";

const App: React.FC = () => {
  
  return (
  <AuthProvider>
    <AppProvider>
        <Routes />
    </AppProvider>
  </AuthProvider>
  );
};

export default App;