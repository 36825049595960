import React from "react";
import "./index.scss";

const Subtitle: React.FC = () => {
  return (
    <div className="subtitle-container">
      <div className="subtitle-content">
        <div className="subtitle-status-current"></div>
        <p className="subtitle-text">Lançamento efetuado</p>
      </div>
      <div className="subtitle-content">
        <div className="subtitle-status-not-applied"></div>
        <p className="subtitle-text">Não ouve lançamento</p>
      </div>
      <div className="subtitle-content">
        <div className="subtitle-status-applied"></div>
        <p className="subtitle-text">Final de semana e feriado</p>
      </div>
    </div>
  );
};

export default Subtitle;
