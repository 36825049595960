import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import AdvancedSelect from "../../form/AdvancedSelect";
import { Formik } from "formik";

import {
  createTimesheet,
  editTimesheetById,
} from "../../../services/timesheet";
import { FormAddBody } from "../../../interfaces/TimesheetFormAdd";
import { CustomMoment } from "../../../interfaces/CustomMoment";
import { useAppContext } from "../../../contexts/appContext";
import "./index.scss";
import { timeConvert, timeGetObj } from "../../../utils/date";
import validateFormAdd from "./validate";
import { useAuth } from "../../../contexts/authContext";

interface CustomProps {
  day: CustomMoment;
  initialData?: FormAddBody;
  timesheets: any;
  onSave?(data: any, replace: boolean): void;
  updateCalendar?(activeDay: CustomMoment): void;
}

interface CustomFormAdd extends FormAddBody {
  sum?: number;
}

interface SetTimeCallback {
  (time: number): void;
}

const FormAdd: React.FC<CustomProps> = ({
  day,
  initialData,
  timesheets,
  onSave,
  updateCalendar,
}) => {
  const defaultData = {
    action: "create",
    time: 0,
    date: day.format("YYYY-MM-DD"),
    project_id: "",
    status_id: "",
    client_id: "",
  };

  const { activeDay, clients, getProjects, getStatus } = useAppContext();
  const [formData, setFormData] = useState<CustomFormAdd>(defaultData);
  const { setAlerts } = useAuth();
  const [loading, setLoad] = useState<boolean>(false);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const madMax = 1440;

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setHours(timeGetObj(initialData.time).hours);
      setMinutes(timeGetObj(initialData.time).minutes);
    }
  }, [initialData]);

  const sumHours = () => {
    const sum = timesheets.reduce(
      (acc: number, current: { time: number }) => acc + current.time,
      0
    );
    return sum - formData.time;
  };

  const sum: number = useMemo(() => {
    if (timesheets && formData.id && formData.action === "edit") {
      const mTimesheets = timesheets.filter(
        (item: { id: string | undefined }) => {
          return item.id !== formData.id;
        }
      );
      return mTimesheets.reduce(
        (acc: number, current: { time: number }) => acc + current.time,
        0
      );
    }
    return (
      timesheets &&
      timesheets.reduce(
        (acc: number, current: { time: number }) => acc + current.time,
        0
      )
    );
  }, [timesheets, formData.action, formData.time]);

  const checkSum = () => {
    return sum + formData.time <= 1440;
  };

  const sumIsValid = checkSum();

  // getters projects and status
  const projects = getProjects(formData.client_id);
  const status = getStatus(formData.client_id, formData.project_id);

  const filteredClients =
    clients &&
    clients.map((client: { id: any; name: any }) => {
      return {
        value: client.id,
        label: client.name,
      };
    });

  const filteredProject =
    projects &&
    projects.map((item: { id: any; name: any }) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const filteredStatus =
    status &&
    status.map((item: { id: any; status: any }) => {
      return {
        value: item.id,
        label: item.status,
      };
    });

  const updateHours = (e: any, onSucess: SetTimeCallback) => {
    const mTime = parseInt(e.target.value) + minutes;
    setHours(parseInt(e.target.value));
    setFormProps("time", mTime);
    if (onSucess) onSucess(mTime);
  };

  const updateMinutes = (e: any, onSucess: SetTimeCallback) => {
    const mTime = parseInt(e.target.value) + hours;
    setMinutes(parseInt(e.target.value));
    setFormProps("time", mTime);
    if (onSucess) onSucess(mTime);
  };

  /**
   * TODO: otimizar atualização dos states
   **/
  const setFormProps = (key: string, value: any) => {
    const prevState = { ...formData };

    if (key === "client_id") prevState.client_id = value;
    if (key === "project_id") prevState.project_id = value;
    if (key === "status_id") prevState.status_id = value;
    if (key === "time") prevState.time = value;

    setFormData(prevState);
  };

  const onSubmit = () => {
    setLoad(true);

    let req = null;
    let alertConfig = {
      isOpen: true,
      variant: "success",
      message: "",
    };

    switch (formData.action) {
      case "edit":
        req = editTimesheetById(formData);
        alertConfig.message = "Timesheet alterada com sucesso!";
        break;
      default:
        req = createTimesheet(formData);
        alertConfig.message = "Timesheet criada com sucesso!";
        break;
    }

    req
      .then((res: any) => {
        if (onSave) {
          onSave(res.data, formData.action === "edit");
        }
        setAlerts([alertConfig]);

        updateCalendar && updateCalendar(activeDay);
        setLoad(false);
        setFormData(defaultData);
        setHours(0);
        setMinutes(0);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        if (error.response) {
          const { msg } = error.response.data;
          setAlerts([
            {
              ...alertConfig,
              message: msg,
              variant: "error",
            },
          ]);
        }
      });
  };

  return (
    <>
      {loading ? (
        <div className="spinner-centered">
          <Spinner
            className="spinner-centered"
            animation="border"
            variant="danger"
          />
        </div>
      ) : (
        <div>
          <Formik
            validate={validateFormAdd}
            enableReinitialize
            initialValues={formData}
            onSubmit={() => {
              onSubmit();
            }}
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <AdvancedSelect
                  name="clients"
                  options={filteredClients}
                  value={formData.client_id}
                  labelName="Cliente"
                  placeholder="Selecione o cliente"
                  disabled={!clients}
                  className="mb-4"
                  handler={(option: any) => {
                    setFieldValue("client_id", option.value);
                    setFormProps("client_id", option.value);
                  }}
                  error={errors.client_id}
                />

                <AdvancedSelect
                  name="projects"
                  options={filteredProject}
                  value={formData.project_id}
                  labelName="Projeto"
                  placeholder="Selecione um projeto"
                  disabled={!projects}
                  className="mb-4"
                  handler={(option: any) => {
                    setFieldValue("project_id", option.value);
                    setFormProps("project_id", option.value);
                  }}
                  error={errors.project_id}
                />

                <AdvancedSelect
                  name="status"
                  options={filteredStatus}
                  value={formData.status_id}
                  labelName="Status"
                  placeholder="Selecione um status"
                  disabled={!status}
                  className="mb-4"
                  handler={(option: any) => {
                    setFieldValue("status_id", option.value);
                    setFormProps("status_id", option.value);
                  }}
                  error={errors.status_id}
                />
                <div className="form-field">
                  <Form.Group className="input-time">
                    <label className="custom-label">Quantidade de horas</label>
                    <div className="input-time-wrapper">
                      <Form.Select
                        onChange={(e) => {
                          updateHours(e, (value) => {
                            setFieldValue("time", value);
                          });
                        }}
                        value={hours}
                      >
                        <option value="0">00</option>
                        <option value="60">01</option>
                        <option value="120">02</option>
                        <option value="180">03</option>
                        <option value="240">04</option>
                        <option value="300">05</option>
                        <option value="360">06</option>
                        <option value="420">07</option>
                        <option value="480">08</option>
                      </Form.Select>
                      <span> : </span>
                      <Form.Select
                        onChange={(e) => {
                          updateMinutes(e, (value) => {
                            setFieldValue("time", value);
                          });
                        }}
                        value={minutes}
                      >
                        <option value="0">00</option>
                        <option value="30">30</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <span className="form-field__alert">
                    {errors.time ?? errors.time}
                  </span>
                </div>

                {!sumIsValid && (
                  <div className="pb-3 form-field">
                    <span className="form-field__alert">
                      A Soma de horas ultrapassa 24 horas!
                    </span>
                  </div>
                )}

                {formData.action === "edit" ? (
                  <div className="custom-edit-btns">
                    <Button
                      type="submit"
                      disabled={!sumIsValid}
                      className="btn btn-custom --red btn-submit"
                    >
                      Editar
                    </Button>
                    <Button
                      type="submit"
                      className={`btn btn-custom --red btn-submit btn-cancel`}
                      onClick={() => {
                        setFormData(defaultData);
                        setHours(0);
                        setMinutes(0);
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button
                      type="submit"
                      disabled={
                        formData.client_id.length === 0 ||
                        formData.project_id.length === 0 ||
                        formData.status_id.length === 0 ||
                        formData.time === 0 ||
                        !sumIsValid
                      }
                      className="btn btn-custom --red btn-submit"
                    >
                      Criar
                    </Button>
                  </>
                )}
                <span className="form-field__alert">
                  {errors.sum && errors.sum}
                </span>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default FormAdd;
