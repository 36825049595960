import React from "react";
import { getLabelMonth } from "../../Calendar/CalendarHelper";
import { CustomMoment } from "../../../interfaces/CustomMoment";
import CalendarImage from "../../../images/Calendar.png";
import './index.scss'

interface CustomProps {
  activeDay: CustomMoment;
  user: any
}

const HeaderDashboard: React.FC<CustomProps> = ({activeDay, user}) => {
  return (
    <div className="header-dashboard">
      <div className="header-dashboard__date">
        <span className="header-dashboard__day book-time-header-left-date">
          {activeDay.format("DD")}
        </span>
        <span className="header-dashboard__month book-time-header-left-month">
          {getLabelMonth(activeDay.format("M"))}
        </span>
      </div>
      <div>
        <div className="header-dashboard__user book-time-header-right">
          <img
            className="header-dashboard__calendar book-time-image-calendar"
            src={CalendarImage}
            alt="Calendar icon"
          />
          <h3>{user.name.split(" ")[0]},</h3>
        </div>
        <h4 className="header-dashboard__subtitle book-time-subtitle">
          Marque suas horas!
        </h4>
      </div>
    </div>
  );
};

export default HeaderDashboard;
