import { FormAddBody } from "../interfaces/TimesheetFormAdd";
import api from "./api";

const getAllClientInfo = async () => {
  return await api.get(`client/all`);
};

const deleteTimeSheet = async () => {
  return await api.delete(`timesheet/delete`);
};

const allNotificationsTimeSheet = async () => {
  return await api.get(`notifications/60`);
};

const getTimesheetsByDate = async (date: string) => {
  return await api.get(`/timesheet/date/${date}`)
}

const createTimesheet = async (data: FormAddBody) => {
  return await api.post(`/timesheet/create`, data);
}

const editTimesheetById = async (data: FormAddBody) => {
  const {id} = data;
  return await api.patch(`/timesheet/update/${id}`, data)
}

export { 
  getAllClientInfo, 
  deleteTimeSheet, 
  allNotificationsTimeSheet, 
  getTimesheetsByDate, 
  createTimesheet,
  editTimesheetById
};