import moment from "moment";
import React, { useState, createContext, useContext, useEffect } from "react";
import { CustomMoment } from "../interfaces/CustomMoment";
import { getAllClientInfo } from "../services/timesheet";
import { useAuth } from "./authContext";

interface AppContextData {
  activeDay: CustomMoment;
  clients: any[];
  changeActiveDay(day: CustomMoment): void;
  getProjects(clientId: string): any;
  getStatus(clientId: string, projectId: string): any;
}

export const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC = ({ children }) => {
  const [activeDay, setActiveDay] = useState<CustomMoment>(moment());
  const [clients, setClients] = useState<any[]>([]);
  const { signed } = useAuth();

  useEffect(() => {
    signed &&
      getAllClientInfo().then((res) => {
        const { data } = res;

        const clientFiltered = data.filter((item: { projects: any }) => {
          return item.projects.length >= 1;
        });

        if (clientFiltered.length >= 1) {
          clientFiltered.forEach((item: { projects: any }, index: number) => {
            const projects = item.projects.filter(
              (pjtObj: { status: string | any[] }) => {
                return pjtObj.status.length >= 1;
              }
            );

            if (projects.length >= 1) {
              item.projects = projects;
            } else {
              delete clientFiltered[index];
            }
          });
        }

        setClients(clientFiltered);
      });
  }, []);

  const changeActiveDay = (day: CustomMoment) => {
    setActiveDay(day);
  };

  const getProjects = (clientId: string) => {
    const activeDataClient =
      clients &&
      clients.find((client: { id: any }) => {
        return client.id === clientId;
      });

    if (activeDataClient && activeDataClient.projects) {
      return activeDataClient.projects;
    }

    return null;
  };

  const getStatus = (clientId: string, projectId: string) => {
    if (projectId === "" || clientId === "") return null;

    const mProjects = getProjects(clientId);
    if (!mProjects) return null;

    const activeProject = mProjects.find((pjt: { id: any }) => {
      return pjt.id === projectId;
    });

    if (!activeProject) return null;

    return activeProject.status;
  };

  return (
    <AppContext.Provider
      value={{ activeDay, clients, changeActiveDay, getProjects, getStatus }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
