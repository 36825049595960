import React from "react";
import { GoogleLogin } from "react-google-login";

import "./index.scss";
import Footer from "../../components/Footer";
import imageCatskilletLogo from "../../images/CatskilletLogo.png";
import imageAcentoTil from "../../images/acentoTil.png";
import imageBolinha from "../../images/Bolinha.png";
import imageGoogleIcon from "../../images/GoogleIcon.png";

import { useAuth } from "../../contexts/authContext";
import CustomizedSnackbars from "../../components/snackbars";

const Login: React.FC = (props) => {
  const { Login, alerts, setAlerts } = useAuth();

  const responseGoogle = async (response: any) => {
    try {
      const emailCatskillet = response.profileObj.email.split("@")[1];

      if (emailCatskillet !== "catskillet.com") {
        setAlerts([
          {
            isOpen: true,
            message: "e-mail informado é inválido!",
            variant: "error",
          },
        ]);
      } else {
        await Login(`${response.tokenId}`);
      }
    } catch (error) {
      setAlerts([
        {
          isOpen: true,
          message: "Houve algum erro, tente novamente!",
          variant: "error",
        },
      ]);
    }
  };
  const onFailure = (error: any) => {
    setAlerts([
      {
        isOpen: true,
        message: "Houve algum erro com seu Login no Google, tente novamente!",
        variant: "error",
      },
    ]);
  };

  return (
    <div className="page-default container-login">
      <div className="wrapper-content">
        <div className="container-content">
          <img
            className="content-imageCatskilletLogo"
            src={imageCatskilletLogo}
            alt="Catskillet Logo"
          />

          <h1 className="content-text-doLogin">FAÇA SEU LOGIN</h1>

          <div className="content-login">
            <GoogleLogin
              clientId={`${process.env.REACT_APP_CLIENT_ID}`}
              render={(renderProps) => (
                <button
                  className="botao-login-google"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    className="content-login-image"
                    src={imageGoogleIcon}
                    alt="Google Icon"
                  />
                  <h2 className="content-login-text">Login com Google</h2>
                </button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
            />
          </div>
          <div className="container-content-bookYourTime">
            <img
              className="content-bookYourTime-imageAcentoTil"
              src={imageAcentoTil}
              alt="Google Icon"
            />
            <h3 className="content-bookYourTime-text">e marque seu horário</h3>
            <img
              className="content-bookYourTime-imageBolinha"
              src={imageBolinha}
              alt="Google Icon"
            />
          </div>
        </div>
      </div>
      {alerts &&
        alerts.map((alertConfig, index) => (
          <CustomizedSnackbars {...alertConfig} key={`alert-${index}`} />
        ))}
      <Footer />
    </div>
  );
};

export default Login;
