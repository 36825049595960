import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { allNotificationsTimeSheet } from "../../services/timesheet";
import "./index.scss";
import Bell from "../../images/bell-icon.png";
import CloseButton from "../../images/close_button.png";
import "./index.scss";
import moment from "moment";


interface CustomProps {
  notification: any;
  date: any;
  id: any;
  key: any;}

const NotificationMenu: React.FC<CustomProps> = ({
  notification,
  date,
  id,
  key,
}) => {
  const [loading, setLoad] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<any>([
    { date: "", message: "" },
  ]);

  useEffect(() => {
    setLoad(true);
    try {
      allNotificationsTimeSheet().then((res: { data: any; }) => {
        setNotifications(res.data);
        setLoad(false);
      });
    } catch (error) {}
  }, []);
  
  return (
    <div className="notification-menu-cotainer">
      <div className="notification-menu-header-cotainer">
        <div className="notification-menu-header-left-content">
          <img
            className="notification-menu-header-left-content-bell"
            src={Bell}
            alt="Bell"
          />
          <h1>Notificações</h1>
          <Link className="notification-menu-header-rightclosebutton" to="/dashboard/">
            <img
            className="rightclosebutton"
            src={CloseButton}
            alt="CloseButton"
            />
          </Link>
        </div>
      </div>
      <div className="notification-menu-list">
          {notifications.map((el: any, id:any) => {
            return (
              <>
                <div >
                  <div  className="notification-menu-list-item">
                    <div  className="notification-menu-list-status-red"></div>
                    <p key={id.id + "T"} >
                      { moment(el.date).format("DD/MM/YYYY")} {el.message}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
      </div >
      
    </div>
  );
};

export default NotificationMenu;