import React, { useState, useEffect } from "react";
import "./index.scss";
import { Container, Row, Col, Offcanvas, Spinner } from "react-bootstrap";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Calendar from "../../components/Calendar";
import Subtitle from "../../components/Subtitle";
import { CustomMoment } from "../../interfaces/CustomMoment";
import api from "../../services/api";

import { useAuth } from "../../contexts/authContext";
import HeaderDashboard from "../../components/Timesheet/HeaderDashboard";
import Booktime from "../../components/Timesheet/BookTime";
import { useAppContext } from "../../contexts/appContext";
import CustomizedSnackbars from "../../components/snackbars";

const Dashboard: React.FC = () => {
  const { user, alerts, setAlerts } = useAuth();
  const { activeDay, changeActiveDay } = useAppContext();

  const [loading, setLoad] = useState<boolean>(true);
  const [calendarItems, setCalendarItems] = useState<any>();
  const [showNav, setNavigation] = useState<boolean>(false);

  const handleClose = () => setNavigation(false);

  useEffect(() => {
    setLoad(true);
    getTimeSheets(activeDay);
  }, []);

  const getTimeSheets = (day: CustomMoment) => {
    try {
      const date = day.format("MM/YYYY");

      api.get(`/timesheet/month/${date}`).then((res) => {
        setCalendarItems(res.data);
        setLoad(false);
      });
    } catch (error) {}
  };

  const setDay = (
    day: CustomMoment,
    openNav: boolean = false,
    updateTimesheets: boolean = false
  ): void => {
    changeActiveDay(day);

    if (openNav) {
      setNavigation(true);
    }

    if (updateTimesheets) {
      getTimeSheets(day);
    }
  };

  return (
    <div className="page-default dashboard">
      <Header />
      {loading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-centered">
            <Spinner
              className="spinner-centered"
              animation="border"
              variant="danger"
            />
          </div>
        </div>
      ) : (
        <div className="dashboard-wrapper">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Calendar
                  activeDay={activeDay}
                  onClickActiveDay={setDay}
                  calendarItems={calendarItems}
                />
              </Col>
            </Row>

            <Offcanvas
              show={showNav}
              onHide={handleClose}
              placement="end"
              className="navigation-canvas"
            >
              <Offcanvas.Body>
                <Offcanvas.Header
                  className="navigation-canvas__header"
                  closeButton
                />
                <div className="navigation-canvas__content">
                  <HeaderDashboard activeDay={activeDay} user={user} />
                  <Booktime updateCalendar={getTimeSheets} />
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
          <Container className="dashboard-subtitle">
            <Row>
              <Subtitle />
            </Row>
          </Container>

          {alerts &&
            alerts.map((alertConfig, index) => (
              <CustomizedSnackbars {...alertConfig} key={`alert-${index}`} />
            ))}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Dashboard;
