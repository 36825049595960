import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

interface RoutePropsCustom extends RouteProps {
    component: any
}

const AfterChangeRoute = () => {
  window.scrollTo(0,0,);
};

const PrivateRoute = (props: RoutePropsCustom) => {
    const { component: Component, ...rest } = props
    const {signed} = useAuth()

    return (
        <Route {...rest} render={props => (
            AfterChangeRoute(),
            localStorage.getItem("@Timesheet:user")
                ? ( <Component {...props}/> )
                : ( <Redirect to={{ pathname: '/login'}}/> )
          )} />
    )
}

export default PrivateRoute