import React from "react";
import moment from "moment";

import arrowLeft from "../../images/arrow-left.png";
import arrowRight from "../../images/arrow-right.png";

interface CustomProps {
  activeDate: moment.Moment;
  setActiveDate: any;
}

const CalendarHeader: React.FC<CustomProps> = ({
  activeDate,
  setActiveDate,
}) => {

  const currMonthName = (): any => {
    moment.updateLocale('pt-br', {
      months : [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
  });
    const activeMonth = activeDate.locale('pt-br');
    return activeMonth.format("MMMM");
  };

  const currYear = (): string => {
    return activeDate.format("YYYY");
  };

  function prevMonth() {
    const activeMonth = activeDate.locale('pt-br');
    return activeMonth.clone().subtract(1, "month");
  }

  function nextMonth() {
    moment.updateLocale('pt-br', {
      months : [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
    });

    const oneYearLate = moment().locale('pt-br').add(1, "year").format("YYYY-MM");
    
    const activeActualDay = activeDate.locale('pt-br');
    const actualDay = activeActualDay.format("YYYY-MM");
      if (actualDay !== oneYearLate) {
        return activeActualDay.clone().add(1, "month");
      } else {
        return activeActualDay;
      }
  }

  return (
    <div className="calendar-header">
      <div
        className="calendar-arrow-previous"
        onClick={() => setActiveDate(prevMonth(), false, true)}
      >
        <img className="arrow-left" src={arrowLeft} alt="Arrow left" />
      </div>
      <div className="calendar-current">
        {currMonthName()} {currYear()}
      </div>
      <div
        className="calendar-arrow-next"
        onClick={() => setActiveDate(nextMonth(), false, true)}
      >
        <img className="arrow-right" src={arrowRight} alt="Arrow right" />
      </div>
    </div>
  );
};

export default CalendarHeader;
