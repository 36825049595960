import React, { useState, useEffect } from "react";
import "./index.scss";
import { Modal, Button } from "react-bootstrap";

interface CustomProps {
  open: boolean;
  title?: string;
  onClose(): void;
  excludeItens: any;
}

const ModalAlertExcludes: React.FC<CustomProps> = ({
  open,
  onClose,
  title,
  children,
  excludeItens,
}) => {
  useEffect(() => {}, [open]);

  return (
    <>
      <Modal show={open} onHide={onClose} className="modal-alert" centered>
        <Modal.Header closeButton style={{ border: "none" }}>
          {title && <h3>{title}</h3>}
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="btn-container">
            <Button variant="primary" onClick={excludeItens}>
              Sim
            </Button>
            <Button variant="secondary" onClick={onClose} >
              Não
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAlertExcludes;
