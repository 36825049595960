import React from "react";
import "./index.scss";
import imageCatskilletLogo from "../../images/CatskilletLogo.png";
import slackImage from "../../images/slack-image.png";
import imageGoogleIcon from "../../images/GoogleIcon.png";
import phoneIcon from "../../images/phone-image.png";

const Footer: React.FC = () => {
  return (
    <div className="container-footer">
      <div className="container-footer-left">
        <div className="container-footer-left-siteCatskillet">
          <a
            href="https://catskillet.com/"
            target="_blank"
            className="container-footer-left-link"
          >
            <img
              className="content-footer-imageCatskilletLogo"
              src={imageCatskilletLogo}
              alt="Catskillet Logo"
            />

            <span>Site Catskillet</span>
          </a>
        </div>
        <div className="container-imageSlackLogo">
          <a href="https://catskillet.slack.com/" target="_blank">
            <img
              className="content-imageSlackLogo"
              src={slackImage}
              alt="Slack Logo"
            />
          </a>
        </div>

        <div className="container-imageGoogleIcon">
          <a href="https://gmail.com" target="_blank">
            <img
              className="content-imageGoogleIcon"
              src={imageGoogleIcon}
              alt="Google Logo"
            />
          </a>
        </div>
      </div>

      <div className="container-footer-rigth">
        <div className="container-phoneIcon">
          <a href="tel:+6494461709">
            <img
              className="content-phoneIcon"
              src={phoneIcon}
              alt="Phone icon"
            />
          </a>
        </div>
        <span>+ 55 (85) 3045-4890</span>
      </div>
    </div>
  );
};

export default Footer;
