function isSelected(day: any, value: any) {
  return value.isSame(day, "day");
}

function getLabelMonth(index: any) {
  const mouths = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return mouths[parseInt(index) - 1];
}

function isToday(day: any) {
  return day.isSame(new Date(), "day");
}

function dayStyles(day: any, value: any) {
  if (isSelected(day, value)) return "selected";
  if (isToday(day)) return "today";
  return "";
}

function getColorClass(day: any, timeSheetStatus: any) {
  if (timeSheetStatus.length === 0) {
    return "default finished";
  }

  const timesheetDay = timeSheetStatus.find((element: any) => {
    return element.date.split("T")[0] === day.split("T")[0];
  });

  if (!timesheetDay) {
    return "default finished";
  }

  switch (timesheetDay.status) {
    case "empty":
    case "incomplete":
      if (timesheetDay.finished) {
        return "incomplete finished";
      }
      return "incomplete";

    case "ok":
      if (timesheetDay.finished) {
        return "complete finished";
      }
      return "complete";

    default:
      return "white";
  }
}

export { isSelected, isToday, dayStyles, getColorClass, getLabelMonth };
