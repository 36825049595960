import React, { useState, createContext, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import api from "../services/api";

interface AppAlerts {
  isOpen: boolean;
  variant: string;
  message: string;
}

interface AuthContextData {
  signed: boolean;
  user: any;
  Login(acessToken: string): Promise<void>;
  Logout(): void;
  setAlerts(alertConfig: Object): void;
  alerts?: AppAlerts[];
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const checkLogin = () => {
  return;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<any[]>([]);
  const navigation = useHistory();

  useEffect(() => {
    const storagedUser = localStorage.getItem("@Timesheet:user");
    const storagedToken = localStorage.getItem("@Timesheet:token");

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
    }

    setLoading(false);
  }, []);

  const Login = async (token: string) => {
    try {
      const response = await api.post("/login", { token });
      const { userData, acessToken, refreshToken } = response.data;

      localStorage.setItem("@Timesheet:user", JSON.stringify(userData));
      localStorage.setItem("@Timesheet:token", acessToken);
      localStorage.setItem("@Timesheet:refresh", refreshToken);

      setUser(userData);
      window.location.href = "/dashboard";
    } catch (error) {
      setAlerts([
        {
          isOpen: true,
          message: "e-mail informado é inválido!",
          variant: "error",
        },
      ]);
    }
  };

  const Logout = () => {
    localStorage.removeItem("@Timesheet:user");
    localStorage.removeItem("@Timesheet:token");
    localStorage.removeItem("@Timesheet:refresh");

    setUser(null);
    window.location.href = "/login";
  };

  if (isLoading) return null;

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Login, Logout, alerts, setAlerts }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
