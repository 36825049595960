import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { timeConvert } from "../../../utils/date";

import "./index.scss";
import ModalAlert from "../../ModalAlert";
import ModalAlertExcludes from "../ModalAlertExcludes";
import { CustomMoment } from "../../../interfaces/CustomMoment";
import api from "../../../services/api";

import editIcon from "../../../images/edit-icon.png";
import { useAuth } from "../../../contexts/authContext";

interface hourAddedInterface {
  activeDay: any;
  timesheets: any;
  onExclude?(data: any): void;
  onEdit(data: any): void;
  updateCalendar?(activeDay: CustomMoment): void;
}

interface AlertInterface {
  innerHtml: string;
  id: number;
}

const HoursAdded: React.FC<hourAddedInterface> = ({
  activeDay,
  timesheets,
  onExclude,
  onEdit,
  updateCalendar,
}) => {
  const [excludeItems, setExcludeItems] = useState<any>([]);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [alert, setAlert] = useState<AlertInterface | null>();
  const { setAlerts } = useAuth();
  

  const addOnExcludeItems = (event: any) => {
    let updatedList = [...excludeItems];
    if (event.target.checked) {
      updatedList = [...excludeItems, event.target.value];
    } else {
      const index = excludeItems.indexOf(event.target.value);
      updatedList.splice(index, 1);
    }
    setExcludeItems(updatedList);
  };

  const getSumHours = (arr: any) => {
    const sum = arr.reduce(
      (acc: number, current: { time: number }) => acc + current.time,
      0
    );
    return timeConvert(sum);
  };

  useEffect(() => {}, [timesheets, alert]);

  const getExcludeItems = () => {
    const timeSheetsFiltered = timesheets.filter((item: any) => {
      return excludeItems.includes(item.id);
    });
    return timeSheetsFiltered;
  };

  const onSubmitExcludeItens = () => {
    const data: any = {
      timeSheetIds: excludeItems,
    };

    api
      .delete(`/timesheet/delete`, {
        data,
      })
      .then((res: any) => {
        if (onExclude) {
          const items = timesheets.filter((e: any) => {
            return !excludeItems.includes(e.id);
          });
          onExclude(items);
        }
        setExcludeItems([]);
        setShowExcludeModal(false);
        setAlerts([
          {
            isOpen: true,
            message: "Timesheet excluída com sucesso!",
            variant: "info",
          },
        ]);
        updateCalendar && updateCalendar(activeDay);
      });
  };

  const openModal = (e: any) => {
    e.preventDefault();
    setShowExcludeModal(true);
  };

  const closeModal = () => {
    setShowExcludeModal(false);
  };

  const getItemFormEdit = (index: string) => {
    const timeSheetsEdited = timesheets.find((item: any) => {
      return item.id === index;
    });
    if (timeSheetsEdited) {
      onEdit({
        action: "edit",
        id: timeSheetsEdited.id,
        time: timeSheetsEdited.time,
        date: activeDay.format("YYYY-MM-DD"),
        project_id: timeSheetsEdited.project_id.id,
        status_id: timeSheetsEdited.status_id.id,
        client_id: timeSheetsEdited.project_id.client.id,
      });
    }
  };

  let sum = 0;

  return (
    <>
      {timesheets && (
        <div>
          {alert && (
            <ModalAlert open={true} labelClose={"Fechar"}>
              <div>
                <h3 className="text-center">{alert.innerHtml}</h3>
              </div>
            </ModalAlert>
          )}
          <ul className="list-hours">
            {timesheets &&
              timesheets.map(
                (el: {
                  id: string;
                  project_id: {
                    client: {
                      name: any;
                    };
                    name: any;
                  };
                  time: number;
                }) => {
                  sum = sum + el.time;
                  return (
                    <li
                      className={`form-check-container ${
                        sum > 480 ? "red" : ""
                      }`}
                      key={el.id + "t"}
                    >
                      <div className="form-check-label">
                        <Form.Check
                          id={el.id}
                          value={el.id}
                          checked={excludeItems.includes(el.id)}
                          onChange={(ev) => addOnExcludeItems(ev)}
                        />
                        <div className="hour-added-container">
                          <div className="hour-added-content-left">
                            <h2>{el.project_id.client.name}</h2>
                            <p>{el.project_id.name}</p>
                            <button
                              onClick={(e) => getItemFormEdit(el.id)}
                              className="btn-edit"
                            >
                              <img src={editIcon} alt="edit icone" />
                              <span>Editar</span>
                            </button>
                          </div>
                          <div className="hour-added-content-right">
                            <span>{timeConvert(el.time)}h</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
          <hr style={{ border: "1px solid #FC473D" }} />
          <div className="book-time-total-hours">
            <h3>TOTAL</h3>
            <span>{getSumHours && getSumHours(timesheets)} horas</span>
          </div>

          <div className="d-flex justify-content-center">
            <ModalAlertExcludes 
              open={showExcludeModal}
              onClose={closeModal}
              excludeItens={onSubmitExcludeItens}
            >
              <div>
                <h3 className="modal-title">Excluir Tarefa</h3>
              </div>
              <div>
                <ul className="list-hours scroll-exclude">
                  {getExcludeItems().map(
                    (el: {
                      id: string;
                      project_id: {
                        client: {
                          name: any;
                        };
                        name: any;
                      };
                      time: number;
                    }) => {
                      return (
                        <li className="form-check-container" key={el.id}>
                          <h2>{el.project_id.client.name}</h2>
                          <p>{el.project_id.name}</p>
                          <div className="hour-added-content-right">
                            <span>{timeConvert(el.time)}</span>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </ModalAlertExcludes>
            <Button
              onClick={(e) => openModal(e)}
              disabled={!excludeItems.length }
              className={`btn btn-custom --red btn-submit ${
                !excludeItems.length ? "button-disabled" : ""
              }`}
            >
              Excluir horário
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default HoursAdded;