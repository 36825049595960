import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { getTimesheetsByDate } from "../../../services/timesheet";
import FormAdd from "../FormAdd";
import HoursAdded from "../HoursAdded";
import "./index.scss";
import { useAppContext } from "../../../contexts/appContext";
import { CustomMoment } from "../../../interfaces/CustomMoment";

interface CustomProps {
  updateCalendar?(activeDay: CustomMoment): void;
}

const BookTime: React.FC<CustomProps> = ({ updateCalendar }) => {
  const { activeDay, changeActiveDay } = useAppContext();
  const [timeSheets, setTimeSheets] = useState<any>();
  const [timeEditData, setTimesheetEditData] = useState<any>(null);
  const [loading, setLoad] = useState<boolean>(true);
  const [keyTab, setKeyTab] = useState<string>("new-hours");

  useEffect(() => {
    getTimesheets();
  }, [activeDay]);

  useEffect(() => {
    setKeyTab("new-hours");
  }, [timeEditData]);

  const getTimesheets = () => {
    setLoad(true);
    const date = activeDay.format("YYYY-MM-DD");

    getTimesheetsByDate(date)
      .then((res) => {
        setTimeSheets(res.data);
        setLoad(false);
      })
      .catch((e) => {});
  };

  const pushOnTimesheetsArray = (data: any, replace: boolean = false) => {
    if (replace) {
      delete data.user_id;
      const mTimesheets = timeSheets.map((item: any) =>
        item.id === data.id ? data : item
      );
      setTimeSheets(mTimesheets);
    } else {
      setTimeSheets([...timeSheets, data]);
    }
  };

 return (
    <div className="book-time" id="bookTime">
      <Tabs
        id="bookTimeNavigation"
        activeKey={keyTab}
        onSelect={(ev: any) => setKeyTab(ev)}
      >
        <Tab eventKey="new-hours" title="Adicionar Horário">
          <FormAdd
            day={activeDay}
            timesheets={timeSheets}
            initialData={timeEditData}
            onSave={pushOnTimesheetsArray}
            updateCalendar={updateCalendar}
            
          />
        </Tab>
        <Tab eventKey="checked-hours" title="Horários Marcados">
          <HoursAdded
            activeDay={activeDay}
            timesheets={timeSheets}
            onExclude={setTimeSheets}
            onEdit={setTimesheetEditData}
            updateCalendar={updateCalendar}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default BookTime;
