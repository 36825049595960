// import { LocalDiningOutlined } from "@material-ui/icons";
import axios from "axios";
import { useAuth } from "../contexts/authContext";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE ?? "http://localhost:5000",
});

api.interceptors.request.use(
  (config: any) => {
    const acessToken = getAcessToken();
    if (acessToken) {
      config.headers.Authorization = `Bearer ${acessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const refreshToken = getRefreshToken();
    // const { Logout } = useAuth();

    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const result = await api.post("/refresh-token", {
            refreshToken,
            acessToken: getAcessToken(),
          });

          const { acessToken } = result.data;
          setAcessToken(acessToken);
          originalConfig.headers.Authorization = `Bearer ${acessToken}`;
          // api.defaults.headers.common["Authorization"] = `Bearer ${acessToken}`
          // window.location.reload();

          return api(originalConfig);
        } catch (_error) {
          localStorage.clear();
          window.location.reload();
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        //Logout();
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err.response.data);
      }
    }
    localStorage.clear();
    window.location.reload();
    return Promise.reject(err);
  }
);

function getAcessToken() {
  const acessToken = localStorage.getItem("@Timesheet:token");
  return acessToken;
}
function setAcessToken(token: string) {
  localStorage.setItem("@Timesheet:token", token);
}
function getRefreshToken() {
  const refreshToken = localStorage.getItem("@Timesheet:refresh");
  return refreshToken;
}

export default api;
