import React, { useState, useEffect } from "react";
import "./index.scss";
import { Modal } from "react-bootstrap";

interface CustomProps {
  open: boolean;
  title?: string;
  labelClose?: string;
}

const ModalAlert: React.FC<CustomProps> = ({
  open,
  title,
  labelClose,
  children,
}) => {
  const [show, setShow] = useState(open);

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {}, [open]);

  return (
    <>
      <Modal show={show} onHide={onClose} className="modal-alert" centered>
        <Modal.Header closeButton style={{ border: "none" }}>
          {title && <h3>{title}</h3>}
        </Modal.Header>
        <Modal.Body>
          {children}
          {labelClose && <button className="label-close" onClick={() => setShow(false)}>{labelClose}</button>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAlert;
